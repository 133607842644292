import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import icalendarPlugin from '@fullcalendar/icalendar'
import enGbLocale from '@fullcalendar/core/locales/en-gb'

document.addEventListener('DOMContentLoaded', () => {
  const calendarEl = document.querySelector('#calendar')

  const calendar = new Calendar(calendarEl, {
    locale: enGbLocale,
    plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, icalendarPlugin ],
    events: {
      url: '/ical.ics',
      format: 'ics',
    },
    height: '100%',
  });
  calendar.render();
})
